import React from "react";
import "./style.scss";
import Card9 from "../../../../components/blocks/card9/card9";
import Separator2 from "../../../../components/separators/separator2";
import Card3 from "../../../../components/blocks/card3/card3";

const Sec2 = ({ data, separator }) => {
  let temp = data?.cms?.recent_story.concat(data?.cms?.recent_listicle);
  const card_1 = temp ? [temp[7]] : [];
  const card_2 = temp ? [temp[2]] : [];
  const card_3 = temp ? [temp[3]] : [];
  const card_4 = temp ? [temp[4]] : [];
  const card_5 = temp ? [temp[1]] : [];

  return (
    <div className="section-two">
      <div className="container is-max-widescreen mt-2">
        <div className="columns is-tablet mr-0 ml-0">
          <div className="column is-two-fifths">
            <Card9 data={card_1} />
          </div>
          <div className="columns column-bottom-line mt-0 is-flex-wrap-wrap">
            <div className="column is-half">
              <Card3 data={card_2} />
            </div>
            <div className="column is-half">
              <Card3 data={card_3} />
            </div>

            <div className="column is-half">
              <Card3 data={card_4} />
            </div>
            <div className="column is-half">
              <Card3 data={card_5} />
            </div>
          </div>
          {/* <div className="column">
            <Card3 data={card_2} />
            <hr />
            <Card3 data={card_3} />
            <hr />
          </div>
          <div className="column">
            <Card3 data={card_4} />
            <hr />
            <Card3 data={card_5} />
            <hr />
          </div> */}
        </div>
        <Separator2 text={separator} />
      </div>
    </div>
  );
};

export default Sec2;
