import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import { format } from "date-fns";

const Card1 = ({ data, separator }) => {
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }
  return (
    <div className="card card1">
      {data[0]?.image && (
        <div className="card-image">
          <Link to={`/${data[0]?.slug}`}>{data[0]?.image.includes("idc-ads-media") ? <img src={addStr(data[0]?.image, 65, "400x400_")} /> : <img src={addStr(data[0]?.image, 37, "400x400_")} />}</Link>
        </div>
      )}
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            {data[0]?.categories[0]?.name && <p className="subtitle is-6 category">{data[0]?.categories[0]?.name}</p>}

            <p className="title is-4">{data[0]?.title}</p>
          </div>
        </div>
        <div className="post-meta">
          {/* <span className="author">Kaylee Garcia - </span> */}
          {data[0]?.pub_date && <span className="date"> {format(new Date(data[0]?.pub_date), "EEEE, MMMM yyyy")} </span>}
        </div>
      </div>
    </div>
  );
};
export default Card1;
