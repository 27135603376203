import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import { format } from "date-fns";

const Card9 = ({ data }) => {
  let featured_post = data[0];
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  return (
    <div className="card card9">
      <Link to={`/${featured_post?.slug}/`}>
        <div
          style={{
            backgroundImage: `url(${featured_post?.image})`,
          }}
          className="bg-image is-flex is-align-items-end"
        >
          <div className="content">
            <div className="post-meta">
              {featured_post?.categories[0]?.name && (
                <p className="subtitle is-5 mt-4 is-uppercase">
                  {featured_post?.categories[0]?.name}
                </p>
              )}
              <p className="title is-3 mt-1">{featured_post?.title}</p>
              {featured_post?.pub_date && (
                <p className="author mt-0 ">
                  {format(new Date(featured_post?.pub_date), "EEEE, MMMM yyyy")}
                </p>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card9;
