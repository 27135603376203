import React from "react";
import "./style.scss";
import Card5 from "../../../../components/blocks/card5/card5";
import Card9 from "../../../../components/blocks/card9/card9";

const Sec7 = ({ data }) => {
  let temp = data?.cms?.recent_listicle.concat(data?.cms?.recent_story);

  let hiro_data = temp?.filter(checkFetured);
  function checkFetured(element) {
    return element?.is_feature_post;
  }

  let hiro_story = data?.cms?.recent_story?.filter(checkFetured);
  let hiro_list = data?.cms?.recent_listicle?.filter(checkFetured);
  const card_1 = hiro_data ? [hiro_story[11]] : [];
  const card_2 = [hiro_story[25]];
  const card_3 = hiro_data ? [hiro_story[6]] : [];
  const card_4 = hiro_data ? [hiro_story[7]] : [];
  const card_5 = hiro_data ? [hiro_list[1]] : [];
  const card_6 = hiro_data ? [hiro_list[2]] : [];

  return (
    <>
      <div className="section-three">
        <div className="container is-max-widescreen mt-2">
          <div className="columns is-tablet mr-0 ml-0">
            <div className="column">
              <Card9 data={card_1} />
            </div>
            <div className="column">
              <Card9 data={card_2} />
            </div>
          </div>
          {/* <Separator1 text="Walk of Fame" /> */}
        </div>
      </div>
      <div className="section-four">
        <div className="container is-max-widescreen mt-2">
          <div className="columns is-tablet mr-0 ml-0">
            <div className="column">
              <Card5 data={card_3} />
            </div>
            <div className="column">
              <Card5 data={card_4} />
            </div>
            <div className="column">
              <Card5 data={card_5} />
            </div>
            <div className="column">
              <Card5 data={card_6} />
            </div>
          </div>
          {/* <Separator1 text="Walk of Fame" /> */}
        </div>
      </div>
    </>
  );
};
export default Sec7;
