import React from "react";
import "./style.scss";
import Card6 from "../../../../components/blocks/card6/card6";
import Separator2 from "../../../../components/separators/separator2";
import Card1 from "../../../../components/blocks/card1/card1";

const Sec6 = ({ data, separator }) => {
  let temp = data?.cms?.recent_story.concat(data?.cms?.recent_listicle);

  const card_1 = temp ? [temp[10]] : [];
  const card_2 = temp ? [temp[15]] : [];
  const card_3 = temp ? [temp[25]] : [];
  const card_4 = temp ? [temp[30]] : [];
  const card_5 = temp ? [temp[35]] : [];

  return (
    <div className="section-two">
      <div className="container is-max-widescreen mt-2">
        <div className="columns is-tablet mr-0 ml-0">
          <div className="column is-two-fifths">
            <Card6 data={card_1} />
          </div>
          <div className="column">
            <div className="columns column-bottom-line is-flex-wrap-wrap">
              <div className="column is-half">
                <Card1 data={card_2} />
              </div>
              <div className="column is-half">
                <Card1 data={card_3} />
              </div>

              <div className="column is-half">
                <Card1 data={card_4} />
              </div>
              <div className="column is-half">
                <Card1 data={card_5} />
              </div>
            </div>
          </div>
        </div>
        {/* <Separator2 text={separator} /> */}
      </div>
    </div>
  );
};

export default Sec6;
