import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Card4 = ({ data }) => {
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  const truncate = (str, max, suffix) => {
    if (!str) {
      str = "";
      max = 0;
    }
    return str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(" "))}${suffix}`;
  };

  return (
    <div className="card card4 is-flex">
      <div className="card-image">
        <Link to={`/${data[0]?.slug}`}>
          {data[0]?.image?.includes("idc") ? <img src={addStr(data[0]?.image, 65, "400x400_")} alt={data[0]?.title} /> : <img src={addStr(data[0]?.image, 37, "400x400_")} alt={data[0]?.title} />}
        </Link>
      </div>
      <div className="card-content">
        <div className="media-content">
          {data[0]?.categories[0]?.name && <span className="tag">{data[0]?.categories[0]?.name}</span>}
          <p className="title is-3">{data[0].title}</p>
          <p className="subtitle is-5 "> {truncate(data[0]?.content?.replace(/<[^>]+>/g, ""), 100, "...")}</p>
        </div>
      </div>
    </div>
  );
};

export default Card4;
