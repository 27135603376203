import React from "react";
import "../pages/mystyles.scss";
import "./cutils.scss";
import Header from "../../../../components/header/Header";
// import Hesader from "../../../../components/header/header1/index";
import Sec7 from "../../../../components/home/components/sec7/sec7";
import Sec8 from "../../../../components/home/components/sec8/sec8";
import Sec2 from "../../../../components/home/components/sec2/sec2";
import Sec6 from "../../../../components/home/components/sec6/sec6";
import Footer from "../../../../components/footer/Footer";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const IndexPage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;
  return (
    <div className="layout">
      <Header category={data?.cms?.categories} domain={data?.cms?.domain} allPosts={allPosts} engine={options} pages={data?.cms?.pages} />
      <Helmet>
        <meta name="commission-factory-verification" content="9a2a0128730b4b2a88a55b488e9873b8" />
        <meta name="ir-site-verification-token" value="-1113842092" />
        <meta name="google-site-verification" content="y5lV9QGSNfjz1IRHuIZcYKOT6mVeiKkTeVY9zU73eAM" />
        <meta name="d103bf43fb93b7f" content="c0b9cd4d499846b447bed15f2e787549" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-FS55TRMLV6"></script>
        <script>
          {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-FS55TRMLV6');`}
        </script>
        <meta name="partnerboostverifycode" content="32dc01246faccb7f5b3cad5016dd5033" />
      </Helmet>
      <Sec7 data={data} />
      <Sec2 data={data} />
      <Sec8 data={data} />
      <Sec6 data={data} />
      {/* <div className="container is-max-widescreen mt-2">
        <div className="columns m-0">
          <div className="column is-two-thirds">
            <List2 data={data?.cms?.recent_story} />
          </div>
          <div className="column">
            <Sidebar recent_story={hiro_data} />
          </div>
        </div>
      </div> */}
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@luxuryhotels.guide"} add="Address: 2140 S Dupont Highway camden, Kent DE 19934" />
    </div>
  );
};
export default IndexPage;

export const query = graphql`
  query home {
    cms {
      domain: CMS_Domain(id: "13") {
        id
        logo
        domain_url
        name
        description
        favicon
      }
      categories: CMS_CategoryByDomain(domain_id: "13") {
        name
        slug
      }
      tech_listicle: CMS_DomainWiseLists(domain_id: "13") {
        title
        image
        slug
        categories {
          name
        }
      }
      tech_story: CMS_DomainWiseStories(domain_id: "13") {
        image
        slug
        title
        categories {
          name
        }
      }
      recent_listicle: CMS_DomainWiseLists(domain_id: "13") {
        title
        is_feature_post
        image
        slug
        description
        pub_date
        categories {
          name
        }
      }
      recent_story: CMS_DomainWiseStories(domain_id: "13") {
        image
        slug
        title
        content
        is_feature_post
        pub_date
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
