import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import Card4 from "../../../../components/blocks/card4/card4";
import Separator2 from "../../../../components/separators/separator2";
import Card5 from "../../../../components/blocks/card5/card5";
import Card9 from "../../../../components/blocks/card9/card9";

const Sec8 = ({ data }) => {
  let temp = data?.cms?.recent_story.concat(data?.cms?.recent_listicle);

  const card_1 = temp ? [temp[10]] : [];
  const card_2 = temp ? [temp[15]] : [];
  const card_3 = temp ? [temp[25]] : [];
  const card_4 = temp ? [temp[30]] : [];
  const card_5 = temp ? [temp[35]] : [];
  const card_6 = temp ? [temp[40]] : [];

  return (
    <div className="section-four">
      <div className="container is-max-widescreen mt-2">
        <div className="columns is-tablet mr-0 ml-0">
          {/* <div className="columns is-mobile mt-2"> */}
          <div className="column is-8">
            <Card4 data={card_1} />
            <hr />
            <Card4 data={card_2} />
            <hr />
            <Card4 data={card_3} />
            <hr />
            <Card4 data={card_4} />
          </div>
          <div className="column is-4">
            <Card9 data={card_6} />
            <hr />
            <Card5 data={card_5} />
            <hr />
          </div>
          {/* </div> */}
        </div>
        {/* <Separator2 text="News" /> */}
      </div>
    </div>
  );
};
export default Sec8;
