import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Card5 = ({ data }) => {
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }
  return (
    <div className="card card5">
      <div className="card-top">
        <Link to={`/${data[0]?.slug}/`}>
          {data[0]?.image?.includes("idc") ? <img src={data[0]?.image} alt={data[0]?.title} /> : <img src={addStr(data[0]?.image, 37, "400x400_")} alt={data[0]?.title} />}
        </Link>
      </div>
      <div class="bottom">
        <div className="post-meta">
          {data[0]?.categories[0]?.name && <span className="tag mb-2">{data[0]?.categories[0]?.name}</span>}
          <p className="title is-4"> {data[0]?.title}</p>
        </div>
      </div>
    </div>
  );
};
export default Card5;
