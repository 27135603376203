import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import { format } from "date-fns";

const Card6 = ({ data, separator }) => {
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }

  const truncate = (str, max, suffix) => {
    if (!str) {
      str = "";
      max = 0;
    }
    return str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(" "))}${suffix}`;
  };
  return (
    <div className="card card6">
      <div className="card-top">
        {data[0]?.image.includes("idc") ? (
          <img src={addStr(data[0]?.image, 65, "400x400_")} alt="Placeholder image" className="image" />
        ) : (
          <img src={addStr(data[0]?.image, 37, "400x400_")} alt="Placeholder image" className="image" />
        )}

        {data[0]?.categories[0]?.name && <span>{data[0]?.categories[0]?.name}</span>}
      </div>
      <div className="bottom">
        <Link to={`/${data[0]?.slug}`}>
          <div className="post-meta">
            <p className="title is-3 mt-4 has-text-centered">{data[0]?.title}</p>
            <p className="subtitle is-5 mt-1 has-text-centered"> {truncate(data[0]?.content?.replace(/<[^>]+>/g, ""), 250, "...")}</p>
            <p className="author mt-0 is-uppercase has-text-centered">{data[0]?.pub_date && <span className="date"> {format(new Date(data[0]?.pub_date), "EEEE, MMMM yyyy")} </span>}</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Card6;
