import React from "react";
import "./style.scss";
import { Link } from "gatsby";

const Card3 = ({ data }) => {
  function addStr(str, index, stringToAdd) {
    if (!str) {
      str = "";
      index = 0;
    }
    return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
  }
  return (
    <div className="card card3">
      <Link to={`/${data[0]?.slug}/`}>
        <div className="card-image">
          {data[0]?.image.includes("idc") ? <img src={addStr(data[0]?.image, 65, "400x400_")} alt={data[0]?.title} /> : <img src={addStr(data[0]?.image, 37, "400x400_")} alt={data[0]?.title} />}
        </div>
      </Link>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            {data[0]?.categories[0]?.name && <p className="subtitle is-6 category">{data[0]?.categories[0]?.name}</p>}
            <p className="title is-4">{data[0]?.title}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card3;
